export default {
  methods: {
    translate (source, callback) {
      let url = 'https://translate.googleapis.com/translate_a/single'
      url += '?client=gtx'
      url += '&hl=zh-CN'
      url += '&sl=zh'
      url += '&tl=en'
      url += '&dt=t'
      url += '&ie=UTF-8'
      url += '&oe=UTF-8'
      url += '&q=' + encodeURIComponent(source)
      //
      let xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let res = ''
            try {
              res = JSON.parse(xhr.responseText)
            } catch (e) {
              console.log(e)
              res = ''
            }
            let ary1 = res[0]
            let result = ''
            if (Array.isArray(ary1) && ary1.length) {
              ary1.forEach(ary2 => {
                if (Array.isArray(ary2) && ary2.length) {
                  result += ary2[0]
                }
              })
            }
            if (typeof callback === 'function') {
              callback(result)
            }
          } else {
            console.log('error', xhr)
          }
        }
      }
      xhr.send(null)
    },
    translate2zh (source, callback) {
      let url = 'https://translate.googleapis.com/translate_a/single'
      url += '?client=gtx'
      url += '&hl=zh-CN'
      url += '&sl=en'
      url += '&tl=zh'
      url += '&dt=t'
      url += '&ie=UTF-8'
      url += '&oe=UTF-8'
      url += '&q=' + encodeURIComponent(source)
      //
      let xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let res = ''
            try {
              res = JSON.parse(xhr.responseText)
            } catch (e) {
              console.log(e)
              res = ''
            }
            let ary1 = res[0]
            let result = ''
            if (Array.isArray(ary1) && ary1.length) {
              ary1.forEach(ary2 => {
                if (Array.isArray(ary2) && ary2.length) {
                  result += ary2[0]
                }
              })
            }
            if (typeof callback === 'function') {
              callback(result)
            }
          } else {
            console.log('error', xhr)
          }
        }
      }
      xhr.send(null)
    }
  }
}
