<template>
  <div class="home-page">
    <div class="intro" :style="{height: pageHeight+'px'}">
      <div class="page-box content-wrap">
        <div action="/labs/pubmed/" method="get" class="search-form">
          <div class="inner-wrap">
            <router-link class="pubmed-logo" :to="{name: 'paperSearch'}">
              <img src="../../assets/imgs/pmop.png" alt="">
            </router-link>
            <div class="search-right">
              <div class="search-box">
                <span class="search-input">
                  <input type="text" v-model="term" @keyup.enter="handleSearch" @focus="handleMouseEnter" @blur="handleMouseLeave">
                  <div class="search-list"></div>
                  <span class="exchange" v-show="showTransfer" @click.stop="transfer"><img src="../../assets/imgs/transfer.png" alt=""></span>
                  <span class="search-clear" v-show="showClear" @click.stop="clearTerm">
                    <span class="clear-box-inner">
                      <a class="clear-btn"></a>
                    </span>
                  </span>
                </span>
                <div class="button" @click.stop="handleSearch"><div class="search-icon"></div></div>
              </div>
              <router-link class="search-ad-btn" :to="{name: 'paperSearchAdvance'}">高级检索</router-link>
              <ul class="search-history-home" v-show="showHistory">
                <li v-for="item in historyList" :key="item.id" @click.stop="handleHistoryClick(item)">{{item.term}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div style="font-size:12px;color: #aaa;position:absolute;bottom: 5px;">
        <span>ICP备案号：</span>
        <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #aaa;">沪ICP备13043474号-7</a>
      </div>
    </div>
  </div>
</template>
<script>
import transMethod from '../paper-search/mixins/translate'
import { updateStorageItem, getStorageItem } from '../../utils/cache'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  mixins: [transMethod],
  data () {
    return {
      term: '',
      showClear: false,
      hasChinese: new RegExp(/[\u4e00-\u9fa5]+/),
      showTransfer: false,
      historyList: [],
      showHistory: false
    }
  },
  computed: {
    pageHeight () {
      return this.$store.state.win.winHeight - 36 - (window.$upgrade ? 22 : 0)
    }
  },
  watch: {
    term (val) {
      if (val) {
        this.showClear = true
        if (this.hasChinese.test(val)) {
          this.showTransfer = true
        } else {
          this.showTransfer = false
        }
      } else {
        this.showClear = false
        this.showTransfer = false
      }
    }
  },
  mounted () {
    document.title = 'PubMed Optimizer'
    let token = getStorageItem('token')
    if (token) {
      this.tokenValidate()
      this.loadHistoryList()
    } else {
      this.tokenRefresh()
    }
  },
  methods: {
    handleSearch () {
      if (!this.hasChinese.test(this.term)) {
        this.$router.push({name: 'paperSearch', query: {term: this.term}})
      }
    },
    clearTerm () {
      this.term = ''
    },
    tokenRefresh () {
      this.$http.post(`${this.httpRoot}/auth/token/refresh`, {}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (res.token) {
            updateStorageItem('token', res.token)
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    tokenValidate () {
      let token = getStorageItem('token')
      this.$http.get(`${this.httpRoot}/auth/token/valid`, authenticate({params: {token}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (!res.valid) {
            // logout
            this.logout()
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    logout () {
      this.$http.post(`${this.httpRoot}/auth/logout`, {}, authenticate())
      .then(res => res.json())
      .then(res => {
        // 请求发出后再退出
        this.$store.dispatch('logout')
        //
        if (res.status === 'success') {
          if (res.token) {
            updateStorageItem('token', res.token)
          }
          if (res.token) {
            this.$router.push({name: 'home'})
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    transfer () {
      this.translate(this.term, en => {
        this.term = en
      })
    },
    loadHistoryList () {
      this.$http.get(`${this.httpRoot}/search/history/recent`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.historyList = res.historyList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    handleHistoryClick (item) {
      this.term = item.term
      this.$emit('on-search', item.term)
      this.handleSearch()
    },
    handleClose () {
      setTimeout(() => {
        this.showHistory = false
      }, 100)
    },
    handleMouseEnter () {
      this.showHistory = true
    },
    handleMouseLeave () {
      this.handleClose()
    }
  }
}
</script>
<style lang="less">
.home-page>.intro {
  background: url(../../assets/imgs/books-outline.svg) no-repeat bottom left/15rem,url(../../assets/imgs/books-outline-rev.svg) no-repeat bottom right/15rem,radial-gradient(#0c4f97,#112e51) center top/100% 50rem;
  background-color: #112e51;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 6px solid #02bfe7;
}
.search-form>.inner-wrap {
  display: flex;
  justify-content: center;
}
.search-form {
  .pubmed-logo {
    margin-top: -0.2rem;
    outline: none;
    text-align: left;
    padding: 0 2rem 0 1rem;
    box-sizing: border-box;
    float: left;
    img {
      width: 255px;
      height: 50px;
    }
  }
  .search-input {
    display: inline-block;
    vertical-align: top;
    position: relative;
    input {
      display: inline-block;
      max-width: none;
      border: 0.5px solid #aeb0b5;
      border-right: 0;
      padding-right: 3rem;
      padding-left: 0.75rem;
      height: 2.5rem;
      width: 26rem;
      font-size: 1rem;
      outline: 0;
      transition: box-shadow 0.15s;
      &:focus {
        border-color: #205493;
      }
    }
    .search-clear {
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      height: 2.5rem;
      box-shadow: -0.4rem 0 0.6rem -0.3rem rgba(0,0,0,0.1);
    }
    .clear-box-inner {
      display: flex;
      padding: 0.5rem;
    }
  }
  .search-right {
    position: relative;
    flex: 0 0 auto;
    .search-box {
      position: relative;
      padding-right: 1rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      float: right;
      &> .button {
        position: relative;
        background-color: #0071bc;
        transition: background-color 0.15s;
        font-size: 1.125rem;
        border: none;
        color: #fff;
        height: 2.55rem;
        line-height: 2.55rem;
        width: 8rem;
        cursor: pointer;
      }
    }
    &> .search-ad-btn {
      position: absolute;
      left: 0;
      top: 2.8rem;
      color: #eee;
      font-size: 0.875rem;
    }
  }
  .search-icon {
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    border: 1px solid #fff;
    border-radius: 50%;
    position: relative;
    margin: 0.5rem auto 0 auto;
    &:after {
      content: ' ';
      position: absolute;
      background: #fff;
      width: 0.8rem;
      height: 0.1rem;
      top: 1.3rem;
      left: 0.9rem;
      transform: rotate(45deg);
    }
  }
  .exchange {
    position: absolute;
    display: block;
    top: 0.5rem;
    right: 3.5rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.search-history-home {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 2.6rem;
  width: 29.8rem;
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,0.25);
  max-height: 400px;
  overflow-y: auto;
  li {
    list-style: none;
    cursor: pointer;
    padding: 5px 7px;
    color: #434642;
    &:hover {
      background: #f1f1f1;
      color: #0076ff;
    }
  }
}
@media screen and (max-width: 500px) and (min-width: 300px) {
  .pubmed-logo {
    display: none;
  }
  .search-box>.search-input>input {
    width: 150px;
  }
}
@media screen and (max-width: 600px) and (min-width: 500px) {
  .pubmed-logo {
    display: none;
  }
  .search-box>.search-input>input {
    width: 200px;
  }
}
@media screen and (max-width: 700px) and (min-width: 600px) {
  .pubmed-logo {
    display: none;
  }
  .search-box>.search-input>input {
    width: 250px;
  }
}
@media screen and (max-width: 900px) and (min-width: 700px) {
  .search-box>.search-input>input {
    width: 250px;
  }
}
</style>
